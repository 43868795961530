.menuList {
  list-style: none;
  height: 100%;
  display: flex;
  overflow-x: scroll;
  z-index: 20;
  position: relative;
}

.menuList::-webkit-scrollbar {
  display: none;
}

.menuItem  {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  // width: 200px;
  max-height: 100%;
  white-space: nowrap;
  z-index: 10;
}
