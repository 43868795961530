.stackCards {
  --stack-cards-gap: calc(0.75 * 1em); // cards gap on scrolling
  // margin: 0 -30%;
  // padding: 0 30%;
}

.horizontalStackCardsItem {
  position: sticky !important;
  position: -webkit-sticky !important;
  // left: calc(1.25 * 1em);
  left: 0px;
  transform-origin: center left !important;
}

.verticalStackCardsItem {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: calc(1.25 * 1em);
  // top: 0;
  transform-origin: center top;
  overflow: hidden;
}