@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Bold Italic'), local('UniNeueBold-Italic'),
        url('UniNeueBold-Italic.woff2') format('woff2'),
        url('UniNeueBold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Thin'), local('UniNeueThin'),
        url('UniNeueThin.woff2') format('woff2'),
        url('UniNeueThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Thin Italic'), local('UniNeueThin-Italic'),
        url('UniNeueThin-Italic.woff2') format('woff2'),
        url('UniNeueThin-Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Black'), local('UniNeueBlack'),
        url('UniNeueBlack.woff2') format('woff2'),
        url('UniNeueBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Regular Italic'), local('UniNeueRegular-Italic'),
        url('UniNeueRegular-Italic.woff2') format('woff2'),
        url('UniNeueRegular-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Light'), local('UniNeueLight'),
        url('UniNeueLight.woff2') format('woff2'),
        url('UniNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Regular'), local('UniNeueRegular'),
        url('UniNeueRegular.woff2') format('woff2'),
        url('UniNeueRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Light Italic'), local('UniNeueLight-Italic'),
        url('UniNeueLight-Italic.woff2') format('woff2'),
        url('UniNeueLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Bold'), local('UniNeueBold'),
        url('UniNeueBold.woff2') format('woff2'),
        url('UniNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue Book';
    src: local('Uni Neue Book Italic'), local('UniNeueBook-Italic'),
        url('UniNeueBook-Italic.woff2') format('woff2'),
        url('UniNeueBook-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Heavy Italic'), local('UniNeueHeavy-Italic'),
        url('UniNeueHeavy-Italic.woff2') format('woff2'),
        url('UniNeueHeavy-Italic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Uni Neue Book';
    src: local('Uni Neue Book'), local('UniNeueBook'),
        url('UniNeueBook.woff2') format('woff2'),
        url('UniNeueBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Heavy'), local('UniNeueHeavy'),
        url('UniNeueHeavy.woff2') format('woff2'),
        url('UniNeueHeavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

