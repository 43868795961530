.inputStyle {
  display: block !important;
  width: 100% !important;
  padding-left: 0.75rem !important;
  // padding-right: 2.5rem;
  padding-top: 0.45rem !important;
  padding-bottom: 0.45rem !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  border-width: 1px !important;
  border-radius: 0.375rem !important;
  border-color: rgba(209, 213, 219, 1) !important;
  // background-color: rgba(243, 244, 246, 1);
  text-align: left !important;
  min-height: 36px;

  @media (min-width: 640px) {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}