.stackCards {
  --stack-cards-gap: calc(0.75 * 1em); // cards gap on scrolling
  // margin: 0 -30%;
  // padding: 0 30%;
}

.stackCardsItem {
  position: sticky !important;
  position: -webkit-sticky !important;
  // left: calc(1.25 * 1em);
  left: 0px;
  transform-origin: center left !important;
}

.invertedBorderRadius {
  position: relative;
}

.invertedBorderRadius::before {
  content: "";
  position: absolute;

  background-color: transparent;
  left: -25px;
  height: 100%;
  width: 25px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 -25px 0 0 #F9FAFB, 0 25px 0 0 #F9FAFB;
}