.glassMenu {
  position: relative;
  transition: all .2s ease-in;
}

.glassMenu::before, .glassMenu::after {
  position: absolute;
  top: 0;
  width: 10rem;
  height: 100%;
  content: '';
  z-index: 1;
}

.glassMenu::before {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(3px) saturate(100%) contrast(45%) brightness(130%);
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
